import React from 'react';
import styles from './../OnboardingWidget.scss';
import { Badge } from 'components/common/Badge';
import { Tick } from 'components/common/Icon';

export const ChecklistCompleted = ({ tasks }: { tasks: number }) => {
  const i18n = Wahanda.Features.isEnabled('CPG-63-self-onboarding-no-go-live')
    ? {
        title: Wahanda.lang.onboardingWidget.completedNoGoLive.title,
        description: Wahanda.lang.onboardingWidget.completedNoGoLive.description,
      }
    : {
        title: Wahanda.lang.onboardingWidget.completed.title,
        description: Wahanda.lang.onboardingWidget.completed.description,
      };

  return (
    <section className={styles.container}>
      <h6 className={styles.title}>{i18n.title}</h6>
      <p className={styles.description}>{i18n.description}</p>
      <p className={styles.checked}>
        <Badge type="success" isLight icon={<Tick size="tiny" />} />
        {Wahanda.Template.render(Wahanda.lang.onboardingWidget.completed.steps, {
          current: tasks,
          total: tasks,
        })}
      </p>
    </section>
  );
};
