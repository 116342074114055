/*
 * API URI generation helper.
 */

import urlTemplate from 'url-template';
import { sprintf } from 'sprintf-js';
import App from 'common/backbone-app';

function venueUri(uri, include?) {
  return () => urlTemplate.parse(App.Api.wsVenueUrl(uri ? `/${uri}` : `.json${include}`));
}

function consultationFormsUri(uri) {
  return () => urlTemplate.parse(App.Api.wsConsultationFormsUrl(uri));
}

function socialMediaUri(uri) {
  return () => urlTemplate.parse(App.Api.wsSocialMediaUrl(uri));
}

function pointOfSaleUri(uri) {
  return () => urlTemplate.parse(App.Api.wsPointOfSaleUrl(uri));
}

function apiEndpointUri(uri) {
  return () => urlTemplate.parse(uri);
}

function supplierUri(uri, include?) {
  return () => urlTemplate.parse(App.Api.wsSupplierUrl(uri ? `/${uri}` : `.json${include}`));
}

function supplierMarketingCampaignsUri(uri) {
  return () => urlTemplate.parse(App.Api.wsSupplierMarketingCampaignsUrl(uri));
}

const namedRoutes = {
  VENUE: venueUri(false, ''),
  AUTHENTICATION: apiEndpointUri('/api/authentication.json'),
  CHECKOUTS: pointOfSaleUri('checkouts.json{?venueCustomerId,date,checkoutIds}'),
  POS_OPERATION: pointOfSaleUri('pos-operation'),
  POS_OPERATION_ABORTED: pointOfSaleUri('pos-operation-aborted'),
  CHECKOUT: pointOfSaleUri('checkout'),
  EXTRANET_SETTINGS: venueUri('extranet-settings.json'),
  PATCH_TEST_INVITES_ENABLE: venueUri('enable-patch-test-invites'),
  PATCH_TEST_INVITES_DISABLE: venueUri('disable-patch-test-invites'),
  CLIENTS_EXPORT: venueUri('customers/export'),
  CLIENTS_BULK_DELETE: venueUri('customers/disable-having-no-appointments'),
  CLIENTS_FILE_UPLOAD: venueUri('customers/import'),
  CLIENTS_IMPORT_PROGRESS: venueUri('customer-import/progress/{progressId}'),
  ACTIVE_CUSTOMERS_IMPORT: venueUri('customers/import/active'),
  POS_DAILY_REPORT: pointOfSaleUri('pos-daily-report{?date}'),
  POS_CLOSE_DAY: pointOfSaleUri('pos-close-day'),
  POS_TRANSACTION: pointOfSaleUri('pos-venue-transaction.json'),
  POS_TRANSACTION_RECEIPT: pointOfSaleUri('receipt/{receiptId}'),
  POS_CHECKOUT_SUMMARY: pointOfSaleUri('checkout/{checkoutId}/summary.json'),
  POS_CHECKOUT_RECEIPT: venueUri('checkout/{checkoutId}/receipt'),
  POS_CHECKOUT_CUSTOMER: venueUri('customer/{venueCustomerId}.json'),
  POS_SETTINGS: pointOfSaleUri('configuration/options'),
  VENUE_BUSINESS_HOURS: venueUri(false, '?include=opening-hours'),
  VENUE_WORKING_HOURS: venueUri('working-hours.json?from={dateFrom}&to={dateTo}'),
  VENUE_WORKING_HOURS_SET_CUSTOM: venueUri('custom-work-time/{date}.json'),
  VENUE_POS_SETTINGS: pointOfSaleUri('configuration/'),
  VENUE_POS_SETTINGS_UPDATE: pointOfSaleUri('configuration/update'),
  VENUE_PRODUCTS: venueUri('products.json'),
  VENUE_PRODUCT: venueUri('product.json'),
  VENUE_PRODUCT_TEMP: venueUri('product/{venueProductId}.json'),
  PRODUCT_SUPPLIER: venueUri('product-supplier'),
  PRODUCT_SUPPLIER_ASSIGN_PRODUCTS: venueUri('product-supplier/{id}/assign-venue-products'),
  PRODUCT_SUPPLIERS: venueUri('product-suppliers'),
  POS_STATUS: pointOfSaleUri('pos-status'),
  EMAIL_CAMPAIGN_COUNT: venueUri('campaign/count'),
  EMAIL_CAMPAIGN_SEND: venueUri('campaign/send.json'),
  EMAIL_CAMPAIGN_PREVIEW: venueUri('campaign/preview'),
  POS_REFUND: pointOfSaleUri('cancellation.json'),
  POS_CANCELLATION_SUMMARY: pointOfSaleUri('cancellation/{cancellationId}/summary.json'),
  POS_SETTLEMENT_REPORT: pointOfSaleUri('pos-settlement-report.pdf{?date}'),
  CALENDAR: venueUri(
    'calendar.json{?date-from,date-to,include,appointment-status-codes,venue-customer-id,utm_source}',
  ),
  NO_SHOW_APPOINTMENT: venueUri('appointment/{id}/noshow'),
  NO_SHOW_APPOINTMENT_GROUP: venueUri('appointment-group/{id}/noshow'),
  CANCEL_APPOINTMENT: venueUri('appointment/{id}/cancel'),
  CANCEL_APPOINTMENT_GROUP: venueUri('appointment-group/{id}/cancel'),
  APPOINTMENT_PAYMENT_PROTECTION: venueUri('appointment/{id}/payment-protection'),
  APPOINTMENT_GROUP_PAYMENT_PROTECTION: venueUri('appointment-group/{id}/payment-protection'),
  TIME_BLOCK_CREATE: venueUri('time-block.json'),
  MENU_GROUP_CREATE: venueUri('menu-group.json'),
  MENU_GROUP: venueUri('menu-group/{id}.json'),
  MENU_GROUP_TEMPLATE: venueUri('menu-with-groups-template.json'),
  CREATE_SERVICES_FROM_TEMPLATE: venueUri('create-services-from-template'),
  VAT_REPORT: venueUri('reports/sales/vat'),
  PERIOD_SALES: venueUri('reports/period-sales'),
  MONTHLY_SALES: venueUri('reports/sales/monthly{?employees,bookingChannel,from,to}'),
  DAILY_SALES: venueUri('reports/sales/daily{?employees,bookingChannel,from,to}'),
  CHANNEL_SALES: venueUri('reports/sales/channels'),
  EMPLOYEE_SALES: venueUri('reports/sales/employees'),
  PRODUCT_SALES: venueUri('reports/sales/products{?employees,from,to}'),
  SALES_SERVICES: venueUri('reports/sales/services{?employees,from,to,bookingChannel}'),
  SALES_SUMMARY: venueUri('reports/sales/summary{?employees,bookingChannel,from,to}'),
  RETTENTION_REPORTS: venueUri('reports/client/retention/monthly'),
  ACQUISITION_REPORTS: venueUri('reports/client/acquisition/monthly'),
  DASHBOARD_REPORTS: apiEndpointUri(
    '/api/venue/{venueId}/reports/sales/overview?from={from}&to={to}',
  ),
  MONTHLY_SALES_REPORT: apiEndpointUri(
    '/api/venue/{venueId}/reports/sales/monthly?from={from}&to={to}',
  ),
  VENUE_GO_LIVE: apiEndpointUri('/api/venue/{venueId}/self-onboarding/go-live'),
  VENUE_OPPORTUNITY_CHECKLIST: apiEndpointUri('/api/checklists/venue/{venueId}'),
  APPOINTMENT_EVENTS: venueUri('activity/appointment-events.json?limit={limit}'),
  CUSTOMER: venueUri('customer'),
  CUSTOMER_MASS_EMAIL: venueUri('customers-mass-email.json?ignoreLastEmailNotAfter=false'),
  INVITE_TO_DOWNLOAD_TREATWELL_APP: venueUri(
    'customer/{venueCustomerId}/send-sms-invite-to-download-treatwell-app',
  ),
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 0.
  WIDGET_TOGGLE_ENABLE: venueUri(),
  WIDGET_EMBED_CODE: venueUri('online-booking-widget-code.json?locale={locale}'),
  REQUEST_FOR_REVIEWS: venueUri('review-request.json'),
  CREATE_PRODUCT_ORDER: venueUri('product-order'),
  PRODUCT_ORDER: venueUri('product-order/{productOrderId}'),
  CANCEL_PRODUCT_ORDER: venueUri('product-order/{productOrderId}/cancel'),
  COMPLETE_PRODUCT_ORDER: venueUri('product-order/{productOrderId}/complete'),
  GET_PRODUCT_ORDERS: venueUri('product-orders'),
  PRODUCT_ORDER_PDF_DOWNLOAD: venueUri('product-order/{productOrderId}/pdf'),
  EMPLOYEES: venueUri('employees.json{?active,takes-appointments,include}'),
  EMPLOYEES_WORKING_HOURS: venueUri(
    'employees/{employeeIds}/working-hours.json?from={dateFrom}&to={dateTo}',
  ),
  EMPLOYEES_WORKING_HOURS_SET_CUSTOM: venueUri('employees/{employeeId}/working-hours/set-custom'),
  EMPLOYEES_WORKING_HOURS_REMOVE_CUSTOM: venueUri(
    'employees/{employeeId}/working-hours/remove-custom',
  ),
  EMPLOYEES_SCHEDULES_SET: venueUri('employees/{employeeId}/schedules'),
  EMPLOYEES_SCHEDULES_GET: venueUri('employees/{employeeId}/schedules/{scheduleId}'),
  EMPLOYEES_SCHEDULES_INVALIDATE: venueUri(
    'employees/{employeeId}/schedules/{scheduleId}/invalidate',
  ),
  EMPLOYEE: venueUri('employee/{id}.json{?include}'),
  EMPLOYEE_EXTERNAL_CALENDAR_SYNC: venueUri('employee/{id}/external-calendar-sync.json'),
  EMPLOYEE_CREATE: venueUri('employee.json?include=employee-offers'),
  EMPLOYEE_CATEGORIES: venueUri('employee-categories.json'),
  EMPLOYEE_ROLE_PERMISSIONS: () => urlTemplate.parse(App.Api.wsUrl('/role-permissions.json')),
  EXTERNAL_SALON_SYSTEMS: venueUri('external-salon-systems'),
  EXTERNAL_SALON_LINK: venueUri('external-salon-link'),
  EXTERNAL_SALON_EMPLOYEES: venueUri('external-salon-link/external-employees'),
  EXTERNAL_SALON_LINK_EMPLOYEES: venueUri('external-salon-link/link-employees'),
  IMPRESSUM: venueUri('impressum'),
  DISCOUNT_RULES: venueUri('discount-rules'),
  EMAIL_CAMPAIGN_SMART_DISCOUNT_PREVIEW: venueUri(
    'campaign/smart-discounts/preview?discountRuleId={discountRuleId}',
  ),
  EMAIL_CAMPAIGN_SMART_DISCOUNT_RECIPIENT_COUNT: venueUri(
    'campaign/smart-discounts/count-target-customers?discountRuleId={selectedDiscountRuleId}',
  ),
  EMAIL_CAMPAIGN_SERVICE_ON_SALE_RECIPIENT_COUNT: venueUri(
    'campaign/service-on-sale/count-target-customers?treatmentGroupId={treatmentGroupId}',
  ),
  EMAIL_CAMPAIGN_APP_FOR_YOUR_CLIENTS_RECIPIENT_COUNT: venueUri(
    'campaign/app-for-your-clients/count-target-customers',
  ),
  SMART_DISCOUNTS_CAMPAIGN_SEND: venueUri('campaign/smart-discounts/send'),
  SERVICE_ON_SALE_CAMPAIGN_SEND: venueUri('campaign/service-on-sale/send'),
  APP_FOR_YOUR_CLIENTS_CAMPAIGN_SEND: venueUri('campaign/app-for-your-clients/send'),
  EMAIL_CAMPAIGN_SERVICE_ON_SALE_TREATMENT_GROUPS: venueUri(
    'campaign/service-on-sale/treatment-groups',
  ),
  EMAIL_CAMPAIGN_SERVICE_ON_SALE_PREVIEW: venueUri(
    'campaign/service-on-sale/preview?treatmentGroupId={treatmentGroupId}',
  ),
  EMAIL_CAMPAIGN_APP_FOR_YOUR_CLIENTS_PREVIEW: venueUri('campaign/app-for-your-clients/preview'),
  EMAIL_CAMPAIGN_BACK_TO_BUSINESS_PREVIEW: venueUri('campaign/venue-opening/preview'),
  CLIENT_APP_SHARE_LINK: venueUri(
    'firebase-dynamic-link/treatwell-app-download-link?communicationChannel={communicationChannel}',
  ),
  APPOINTMENT: venueUri('appointment/{id}.json'),
  APPOINTMENT_GROUP: venueUri('appointment-group/{id}.json'),
  APPOINTMENTS: venueUri('appointments'),
  PARTNER_SITE_SETTINGS: venueUri('microsite-settings.json'),
  PARTNER_SITE_SUBDOMAIN_VALIDATION: venueUri(
    'microsite-subdomain-validation?candidate={subdomain}',
  ),
  PARTNER_SITE_CUSTOM_DOMAIN_VALIDATION: venueUri(
    'microsite-custom-domain-validation?candidate={customDomain}',
  ),
  VENUE_RANKING_BOOST_STATUS: venueUri('venue-search-ranking-boost-status'),
  PREVIOUS_PAYMENT: apiEndpointUri('/api/supplier/{supplierId}/previous-payment'),
  INITIALISE_STRIPE_IDEAL_CHECKOUT_SUBMISSION: apiEndpointUri(
    '/api/supplier/{supplierId}/payment/initialise-stripe-ideal-checkout-submission',
  ),
  SIGNATURE_SEARCH: pointOfSaleUri('signature/search{?signature,source}'),
  POS_EXPORTS: pointOfSaleUri('exports'),
  INIT_POS_EXPORT: pointOfSaleUri('export/{type}{?from,to}'),
  DOWNLOAD_POS_EXPORT: pointOfSaleUri('export/{type}/{id}'),
  TRANSACTIONS_EXPORT: pointOfSaleUri('transactions-export{?startDate,endDate}'),
  BACK_TO_BUSINESS_REACH: venueUri('campaign/venue-opening/reach'),
  BACK_TO_BUSINESS_STATUS: venueUri('campaign/venue-opening/status'),
  BACK_TO_BUSINESS_CAMPAIGN_SEND: venueUri('campaign/venue-opening/send'),
  APPOINTMENT_AVAILABILITY: venueUri(
    'appointments/get-closed-appointment-slots{?serviceId,employeeId,date,duration,appointmentId,processingTime,finishingTime}',
  ),
  COVID_REPORT: venueUri('reports/covid19{?notBefore,notAfter}'),
  COVID_UNLIMIT_OPERATION: venueUri('unlimit-operation'),
  TREATMENTS: apiEndpointUri('/api/treatments.json{?include}'),
  FBE_UNINSTALL: socialMediaUri('facebook-business-extension/uninstall-fbe'),
  FBE_CHECK_STATUS: socialMediaUri('facebook-business-extension/status'),
  FBE_BUSINESS_CONFIG: socialMediaUri('facebook-business-extension/business-config'),
  VENUE_ENABLED_FEATURES: apiEndpointUri('/api/venue/{venueId}/enabled-features'),
  FBE_INTEGRATION_ASSETS: socialMediaUri('facebook-business-extension/assets'),
  TERMS_OF_SERVICE_BY_CHANNEL: apiEndpointUri('/api/terms-of-service?channelCode={channelCode}'),
  BOOKING_ENQUIRIES: venueUri('bookings-enquiries.json{?entity,type,date-from,date-to}'),
  ORDER_INFO: supplierUri('order/{id}.json'),
  EXTRANET_VENUE_GALLERY_DOWNLOAD: venueUri('download/source-images'),

  CONSULTATION_FORMS_CUSTOMER_FORM: consultationFormsUri(
    '/customer-form/reference?venueCustomerId={venueCustomerId}&date={date}',
  ),

  CONSULTATION_FORMS_TEMPLATES: consultationFormsUri('/venue/{venueId}/form-template'),
  CONSULTATION_FORMS_TEMPLATES_BY_ID: consultationFormsUri(
    '/venue/{venueId}/form-template/{formId}',
  ),
  CONSULTATION_FORMS_TEMPLATES_ENABLE: consultationFormsUri(
    '/venue/{venueId}/form-template/{templateId}/enable',
  ),
  CONSULTATION_FORMS_TEMPLATES_DISABLE: consultationFormsUri(
    '/venue/{venueId}/form-template/{templateId}/disable',
  ),
  SUPPLIER_MARKETING_CAMPAIGNS_VENUE_SETTINGS: supplierMarketingCampaignsUri(''),
  SUPPLIER_MARKETING_CAMPAIGNS_ENABLE_BIRTHDAY_CONGRATULATIONS: supplierMarketingCampaignsUri(
    '/enable-birthday-campaign',
  ),
  SUPPLIER_MARKETING_CAMPAIGNS_DISABLE_BIRTHDAY_CONGRATULATIONS: supplierMarketingCampaignsUri(
    '/disable-birthday-campaign',
  ),

  GEOCODE: venueUri('geocode{?addressLine1,city,postcode}'),

  IDENTITY_VERIFICATION: apiEndpointUri(
    '/api/supplier/{supplierId}/stripe-onboarding.json{?returnUrl,refreshUrl}',
  ),
  ALL_SERVICES_LIST: venueUri('menu.json?service-types=T'),
};

export type RouteName = keyof typeof namedRoutes;

/**
 * The URL generator.
 *
 * @param {String} routeName Name of the route
 * @param {Object} params List of parameters to map to the variables in template
 *
 * @return {String} The URL
 */
function apiUrl(routeName: RouteName, params = {}) {
  const template = namedRoutes[routeName];

  if (!template) {
    throw new Error(sprintf('Named route "%s" not found', routeName));
  }
  return template().expand(params);
}

apiUrl.routes = namedRoutes;

export default apiUrl;

export const routes = namedRoutes;
