import React from 'react';
import Wahanda from 'common/wahanda';
import App from 'common/backbone-app';
import LANGUAGE_LOCALES from 'config/languageLocales';

import { useMutation } from 'react-query';
import { post } from 'reduxStore/apiService';
import apiUrl from 'common/api-url';

import { Notice } from 'components/common/Notice';
import { Button } from 'components/common/Button';
import { Form, Checkbox, Input } from 'components/common/Form';
import Loader from 'components/common/Loader';

import { LoginAnalytics } from '../tracking';
import { validations } from './validations';

import style from './LoginForm.scss';

interface Props {
  route: string;
}

type FormValues = {
  user: string;
  password: string;
  persistentLogin: boolean;
};

enum FormResponses {
  ACCOUNT_AUTHENTICATED = 'ACCOUNT_AUTHENTICATED',
  PROFILE_AUTHENTICATED = 'PROFILE_AUTHENTICATED',
  LOCKED = 'LOCKED',
  REQUIRES_RESET = 'REQUIRES_RESET',
  NOT_AUTHENTICATED = 'NOT_AUTHENTICATED',
}

export const LoginForm: React.FC<Props> = ({ route }) => {
  const [errorMessage, setErrorMessage] = React.useState();
  const [isRedirecting, setIsRedirecting] = React.useState(false);
  const [errorType, setErrorType] = React.useState<FormResponses>();
  const errorMessageRef = React.useRef<HTMLDivElement>(null);
  const forgotPasswordLink = App.getPortalRootUrl() + Wahanda.lang.login.links.requestPassword;
  const errorsWithResetLink = [FormResponses.REQUIRES_RESET, FormResponses.LOCKED];

  /**
   * For LV the signup link should point to the self sign up flow,
   * for other markets it should point to portal.
   */
  const baseSignUpLink = App.domainLocale != LANGUAGE_LOCALES.LV ? App.getPortalRootUrl() : '';
  const signUpLink = baseSignUpLink + Wahanda.lang.login.links.signup;

  const onSuccess = (response) => {
    setErrorType(response.result);
    switch (response.result) {
      case FormResponses.ACCOUNT_AUTHENTICATED:
      case FormResponses.PROFILE_AUTHENTICATED:
        setErrorMessage(undefined);
        redirectUser();
        break;
      case FormResponses.LOCKED:
        setErrorMessage(Wahanda.lang.login.lockedErrorMessage);
        break;
      case FormResponses.REQUIRES_RESET:
        setErrorMessage(Wahanda.lang.login.resetErrorMessage);
        break;
      case FormResponses.NOT_AUTHENTICATED:
      default:
        LoginAnalytics.trackErrorPassword();
        setErrorMessage(Wahanda.lang.login.unauthorisedErrorMessage);
        break;
    }
  };

  React.useLayoutEffect(() => {
    if (errorMessage && errorsWithResetLink.includes(errorType!)) {
      const link: HTMLAnchorElement | undefined = errorMessageRef?.current?.getElementsByTagName(
        'a',
      )[0];

      link?.setAttribute('href', forgotPasswordLink);
    }
  }, [errorMessage, errorType]);

  const redirectUser = () => {
    const config = new App.Models.Config();
    setIsRedirecting(true);

    config.fetch({
      success: () => {
        // Set the config to be used by libs requiring it
        App.config = config;

        Wahanda.Tracking.clearIdentify();
        Wahanda.Tracking.clearGroup();

        if (!config.contentChannelMatches(App.domainChannelCode())) {
          LoginAnalytics.trackLoginSubmit();

          let uriComponent = decodeURIComponent(route);
          if (uriComponent === 'undefined') {
            uriComponent = '';
          }
          // Content channel is different than the user is on now. Redirect.
          window.location = (App.getContentChannelUrl(config.getContentChannel()) +
            uriComponent) as any;
          return;
        }

        let url;
        if (config.get('venue')) {
          if (App.isTaxAuditor()) {
            url = Wahanda.Url.getFullUrl('reports', 'transactions');
          } else if (route) {
            url = decodeURIComponent(route);
          } else {
            url = Wahanda.Url.getFullUrl('calendar');
          }

          LoginAnalytics.trackLoginSubmit();
          window.location.href = url;
        } else {
          setIsRedirecting(false);
          LoginAnalytics.trackErrorNoVenues();
          setErrorMessage(Wahanda.lang.login.noVenuesErrorMessage);
        }
      },
      error: () => {
        setIsRedirecting(false);
        LoginAnalytics.trackErrorNoVenues();
        setErrorMessage(Wahanda.lang.login.noVenuesErrorMessage);
      },
    });
  };

  const [mutate, { isLoading }] = useMutation(
    (formData) => post(apiUrl('AUTHENTICATION'), formData),
    { onSuccess },
  );

  const handleSubmit = (values) => {
    LoginAnalytics.trackLoginClick();
    mutate({ ...values, persistentLogin: values.persistentLogin || false });
  };

  const showSalonBoosterMigration = () => {
    return document.location.search.indexOf('sbMigration') > -1;
  };

  const handleRegisterRedirect = () => {
    LoginAnalytics.trackSignupClick();
  };

  const handleForgotPasswordRedirect = () => {
    LoginAnalytics.trackForgotClick();
  };

  return (
    <>
      {errorMessage && (
        <div ref={errorMessageRef}>
          <Notice
            className={style.errorMessage}
            type="alert"
            dataTest={`error-message-${errorType}`}
            message={<div dangerouslySetInnerHTML={{ __html: errorMessage! }} />}
          />
        </div>
      )}
      {showSalonBoosterMigration() && (
        <Notice
          className={style.errorMessage}
          message={Wahanda.lang.login.salonboosterMigrationMessage}
        />
      )}
      <div className={style.login}>
        <Form<FormValues> onSubmit={handleSubmit}>
          {({ errors, getValues }) => {
            const fields = validations({ errors });
            const areFieldsBlank =
              errors.user &&
              errors.password &&
              getValues('user') === undefined &&
              getValues('password') === undefined;

            if (areFieldsBlank) {
              LoginAnalytics.trackInvalidFields();
            }

            return (
              <>
                <div className={style.field}>
                  <Input {...fields.user} />
                </div>
                <div className={style.field}>
                  <Input {...fields.password} />
                </div>
                <div className={style.remember}>
                  <Checkbox name="persistentLogin" label={Wahanda.lang.login.rememberUserText} />
                  <a
                    className={style.forgotLink}
                    href={forgotPasswordLink}
                    onClick={handleForgotPasswordRedirect}
                  >
                    {Wahanda.lang.login.forgotPassword}
                  </a>
                </div>
                <Button
                  dataTest="login-form-submit"
                  label={Wahanda.lang.login.logInButton}
                  type="submit"
                  size="large"
                  fullWidth
                />
              </>
            );
          }}
        </Form>
      </div>
      <div className={style.signup}>
        <div className={style.signupText}>
          <h2>{Wahanda.lang.login.registerText}</h2>
        </div>
        <Button
          label={Wahanda.lang.login.registerButton}
          href={signUpLink}
          onClick={handleRegisterRedirect}
          size="large"
          colour="plain"
          fullWidth
        />
      </div>
      {(isLoading || isRedirecting) && <Loader positionAbsolute />}
    </>
  );
};
