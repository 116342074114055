function generateSimpleUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  }) as ReturnType<typeof crypto.randomUUID>;
}

if (!window.crypto.randomUUID) {
  window.crypto.randomUUID = generateSimpleUUID;
}
