import React from 'react';
import { Button } from 'components/common/Button';
import style from './FeatureSignup.scss';
import Wahanda from 'common/wahanda';

const lang = Wahanda.lang.marketing.clientForms.landingPage.comingSoon;

interface ComingSoonPageProps {
  callback: () => void;
}

export const ComingSoonPage: React.FC<ComingSoonPageProps> = ({ callback }) => (
  <div className={style.container} data-test="consultation-forms-sign-up-page">
    <div className={style.icon + ' ' + style.comingSoonIcon} />
    <p>
      <strong>{lang.header}</strong>
    </p>
    <p>{lang.text}</p>
    <Button
      dataTest="consultation-forms-sign-up-button"
      label={lang.buttonText}
      onClick={callback}
    />
  </div>
);
