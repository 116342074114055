import React from 'react';
import style from './CustomerFormLinks.scss';
import { useAppointmentCustomerForm } from './hooks';
import { BadgeRedesigned } from 'components/common/BadgeRedesigned';
import { CustomerFormLinkStatus } from './types';
import Wahanda from 'common/wahanda';
import { Chevron } from 'components/common/Icon';

const lang = Wahanda.lang.UDVCovidFormLink; // TODO this needs renaming to customer forms rather than covid
const consultationFormLang = Wahanda.lang.marketing.clientForms;

interface Props {
  customerId?: string;
  appointmentDate: string;
}

export const CustomerFormLinks = ({ customerId, appointmentDate }: Props) => {
  const { isLoading, isError, data } = useAppointmentCustomerForm({
    customerId,
    appointmentDate,
  });

  if (isLoading || isError || !data?.length) {
    return null;
  }

  const badgeByStatus = (status) => {
    let badge: JSX.Element | null;
    switch (status) {
      case CustomerFormLinkStatus.SENT:
        badge = <BadgeRedesigned text={consultationFormLang.pending.badge} type="expose" isLight />;
        break;
      case CustomerFormLinkStatus.COMPLETED:
        badge = <BadgeRedesigned text={lang.completed.badge} type="info" isLight />;
        break;
      case CustomerFormLinkStatus.CANT_BE_SENT:
        badge = null;
        break;
      case CustomerFormLinkStatus.SCHEDULED:
      default:
        badge = <BadgeRedesigned text={consultationFormLang.pending.badge} type="expose" isLight />;
        break;
    }

    return badge;
  };

  const renderTitle = ({ status, title }) => {
    let element: JSX.Element;
    switch (status) {
      case CustomerFormLinkStatus.CANT_BE_SENT:
        element = (
          <div>
            <span className={style.title}>{title}</span>
            <div className={style.error}>{consultationFormLang.sendError}</div>
          </div>
        );
        break;
      case CustomerFormLinkStatus.SENT:
      case CustomerFormLinkStatus.COMPLETED:
      case CustomerFormLinkStatus.SCHEDULED:
      default:
        element = (
          <div className={style.title}>
            {badgeByStatus(status)}
            <span className={style.text}>{title}</span>
          </div>
        );
        break;
    }
    return element;
  };

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        {data.map((item) => (
          <a
            className={style.link}
            href={item?.uri}
            target="_blank"
            rel="noreferrer noopener"
            key={item.id}
          >
            <div className={style.inner}>
              {renderTitle(item)}
              <Chevron />
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};
