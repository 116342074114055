import { launchUnsecureSalesforceBot } from 'common/salesforce-bot';
import Wahanda from 'common/wahanda';
import { ChatSourceType } from 'src/tracking-container/events/chat';

const initSalesforceBot = async (source: ChatSourceType) => {
  if (!Wahanda.Features.isEnabled('CD-1312-salesforce-bot-secure-authentication')) {
    return launchUnsecureSalesforceBot({ source });
  }

  if (App.isMasquerading()) {
    return Wahanda.ToastNotifications.sendMessage(
      'Salesforce bot is not available in masquerade mode. It requires secure authentication.',
      { variant: 'danger', timeout: 30000 },
    );
  }

  App.trigger(Wahanda.Event.SALESFORCE_BOT_OPEN, { source });
};

export const openChat = async (source: ChatSourceType): Promise<void> => {
  const service = Wahanda.Features.isEnabled('CD-823-salesforce-bot') ? 'Salesforce' : 'HelpCenter';

  try {
    switch (service) {
      case 'Salesforce':
        return initSalesforceBot(source);
      case 'HelpCenter':
        return openHelpCenter();
    }
  } catch (error) {
    Wahanda.ToastNotifications.sendMessage(error, { variant: 'danger', timeout: 10000 });
  }
};

const openHelpCenter = () => {
  const helpCenterUrl = 'https://partnercare.treatwell.com/s/?language=en_GB';
  window.open(helpCenterUrl, '_blank');
};
