export enum AppEvent {
  // Analytics
  CALENDAR_VIEW = 'calendar:view',
  CALENDAR_SWIPE = 'calendar:swipe',
  CALENDAR_NEW_APPOINTMENT = 'calendar:new-appointment',
  APPOINTMENT_DRAG_AND_DROP_SUBMITED = 'calendar:submited-drag-and-drop',
  CALENDAR_APPOINTMENT_OPEN = 'calendar:appointment-open',
  CHECK_OUT_PRODUCT_ADDED = 'calendar:product-added',
  CHECK_OUT_VOUCHER_ADDED = 'calendar:voucher-added',
  CHECK_OUT_VOUCHER_REMOVED = 'calendar:voucher-removed',

  // App related
  APP_LOADED = 'app:loaded',
  APP_VENUE_CHANGED = 'app:venue-changed',
  APP_VENUE_IS_CHANGING = 'app-venue-is-changing',
  APP_CONNECTION_ERROR = 'app:connection-error',
  APP_CONTEXT_CHANGED = 'app:context-changed',
  APP_CONFIG_LOADED = 'app:conig-loaded',
  APP_LOAD_STARTED = 'app:loading',
  APP_ROUTE_LABELS_CHANGED = 'app:routeLabelsChanged',
  APP_FEATURES_LOADED = 'app:featuresLoaded',
  APP_FEATURES_REQUEST = 'app:featuresRequest',

  // Calendar related
  CALENDAR_ALLOWED_TYPES = 'calendar:allowed-calendar-types',
  CALENDAR_BLOCK_TIME_OPENED = 'calendar:block-time-opened',
  CALENDAR_BLOCK_TIME_SAVED = 'calendar:block-time-saved',
  CALENDAR_BLOCK_TIME_DELETED = 'calendar:block-time-deleted',
  CALENDAR_MULTIPLE_BLOCKS_OPENED = 'calendar:multiblocks-open',
  CALENDAR_MULTIPLE_BLOCKS_SAVED = 'calendar:multiblocks-saved',
  CALENDAR_MULTIPLE_BLOCKS_CANCEL = 'calendar:multiblocks-cancelled',
  CALENDAR_WORK_TIME_OPENED = 'calendar:work-time-opened',
  CALENDAR_WORK_TIME_SAVED = 'calendar:work-time-saved',
  CALENDAR_DISPLAY_TYPE_CHANGE = 'calendar:display-type-change',
  CALENDAR_GO_PREV = 'calendar:request-go-prev',
  CALENDAR_GO_NEXT = 'calendar:request-go-next',
  CALENDAR_GO_TODAY = 'calendar:request-go-today',
  CALENDAR_REQUEST_DATE_CHANGE = 'calendar:request-date-change',
  CALENDAR_RESOURCE_EMPLOYEE_CHANGE = 'calendar:resource-employee-change',
  CALENDAR_TYPE_CHANGE = 'calendar:type-change',
  CALENDAR_AVAILABILITY_SAVED = 'calendar:availability-saved',
  CALENDAR_DAYS_CLOSED_OUT = 'calendar:days-closed-out',
  CALENDAR_DATEPICKER_RENDERED = 'calendar:datepicker-view-rendered',
  CALENDAR_ALERTS_HIDDEN = 'calendar:alerts-hidden',
  CALENDAR_REQUEST_REFRESH = 'calendar:request-refresh',
  CALENDAR_REQUEST_REFRESH_MAIN = 'calendar:request-refresh-main',
  CALENDAR_BUTTONS_TOGGLED = 'calendar:button-visibility-toggled',
  CALENDAR_RESOURCE_CHANGE = 'calendar:resource-change',

  CALENDAR_RESCHEDULE_MODE_START = 'calendar-reschedule:start',
  CALENDAR_RESCHEDULE_MODE_CANCEL = 'calendar-reschedule:cancel',
  CALENDAR_RESCHEDULE_MODE_FINISH = 'calendar-reschedule:finish',
  CALENDAR_REBOOKING_MODE_START = 'calendar-rebooking:start',
  CALENDAR_REBOOKING_MODE_CANCEL = 'calendar-rebooking:cancel',
  CALENDAR_REBOOKING_MODE_FINISH = 'calendar-rebooking:finish',

  EMPLOYEE_WORKING_HOURS_TOGGLE = 'calendar:employee-working-hours-toggle',

  CALENDAR_DRAWER_OPEN = 'calendar:drawer-open',
  CALENDAR_DRAWER_CLOSE = 'calendar:drawer-close',
  CALENDAR_OPEN_APPOINTMENT_MODAL = 'calendar:open-apointment-modal',
  CALENDAR_REFETCH_WAITINGLISTS = 'calendar:refetch-waitinglists',

  // Menu
  MENU_OFFER_DIALOG_OPENED = 'menu:offer-dialog-opened',
  MENU_OFFER_DIALOG_CLOSED = 'menu:offer-dialog-closed',
  MENU_OFFER_SAVED = 'menu-offer:saved',
  MENU_OFFER_DIALOG_CANCELLED = 'menu:offer-dialog-cancelled',
  MENU_QUICK_WIZARD_OPENED = 'menu:quick-menu-creation-wizard-opened',
  MENU_QUICK_WIZARD_SAVED = 'menu:quick-menu-creation-wizard-saved',
  MENU_UPGRADED_TO_SELLING_ALL_OFFERS = 'menu:upgraded-to-selling-all-offers',
  MENU_OFFER_CHANGED_GROUP = 'menu:offer-group-change',
  MENU_OFFER_CHANGED_ORDER = 'menu:offer-reordered',
  MENU_OFFER_CREATED_DISCOUNT = 'menu:offer-created-discount',
  MENU_OFFER_REMOVED_DISCOUNT = 'menu:offer-removed-discount',
  MENU_OFFER_UPDATED_DISCOUNT = 'menu:offer-updated-discount',
  MENU_FILTER_CHANGE = 'menu:filter-change',
  MENU_SUB_FILTER_CHANGE = 'menu:sub-filter-change',
  MENU_OFFER_FEATURE_CHANGE = 'menu:offer-feature-change',
  MENU_GROUP_ARCHIVED = 'menu-group:archived',
  MENU_GROUP_CREATED = 'menu-group:created',
  MENU_GROUP_UPDATED = 'menu-group:updated',
  MENU_GROUP_DELETED = 'menu-group:deleted',
  MENU_GROUP_CHANGED_ORDER = 'MENU_GROUP_CHANGED_ORDER',
  MENU_OFFER_SKU_ADD_DIALOG_OPENED = 'menu-offer:sku-add-dialog-opened',
  MENU_OFFER_PRICING_RANGE_ADDED = 'menu-offer:pricing-range-added',
  MENU_OFFER_NAME_CHANGED = 'menu-offer:name-change',
  MENU_OFFER_TREATMENT_CHANGED = 'menu-offer:treatment-change',
  MENU_GROUP_OFF_PEAK_DISCOUNTS_RESET = 'MENU_GROUP_OFF_PEAK_DISCOUNTS_RESET',
  MENU_DISCOUNT_SAVED = 'menu:discount-saved',
  OFFER_DIALOG_SAVED = 'menu:offer-dialog-saved',
  RESET_SERVICE_EDIT_TRACKING = 'menu:reset-service-edit-tracking',

  IMAGE_LIST_DIALOG_OPENED = 'image-list:opened',
  IMAGE_LIST_IMAGE_SELECTED = 'image-list:image-seleced',
  IMAGE_LIST_RESORTED = 'image-list:resorted',

  // Appointment events
  APPOINTMENT_OPENED = 'appointment:opened', // nothing triggers this even,
  APPOINTMENT_CLOSED = 'appointment:closed',
  APPOINTMENT_ACCESS_FORBIDDEN = 'appointment:forbidden',
  APPOINTMENT_CONFIRMED = 'appointment:confirmed',
  APPOINTMENT_REJECTED = 'appointment:rejected',
  APPOINTMENT_CANCELLED = 'appointment:cancelled',
  APPOINTMENT_SAVED = 'appointment:saved',
  APPOINTMENT_WALKIN_CLICKED = 'appointment:walkin-click',
  APPOINTMENT_RESCHEDULED_BLOCK_CREATED = 'appointment:block-created-insteadof',
  APPOINTMENT_CONSUMER_PHONE_CLICKED = 'appointment:phone-clicked',
  APPOINTMENT_CONSUMER_EMAIL_CLICKED = 'appointment:email-clicked',
  APPOINTMENT_CHECK_OUT_FORM_OPENED = 'appointment:check-out-form-opened',
  APPOINTMENT_CHECKED_OUT = 'appointment:checked-out',
  APPOINTMENT_NEW_FORM_OPENED = 'appointment:form-opened',
  APPOINTMENT_NEW_FORM_OPENED_WITH_REBOOKING = 'appointment:form-opened-w-rebooking',
  CALENDAR_EDIT_FORM_OPENED = 'calendar:edit-form-opened',
  CALENDAR_EDIT_FORM_TAB_SWITCH = 'calendar:edit-form-tab-switch',
  APPOINTMENT_SET_NOSHOW = 'appointment:set-as-no-show',
  APPOINTMENT_FORM_SUBMIT = 'appointment:form-submission',
  APPOINTMENT_FORM_ERRORS = 'appointment:form-errors',
  APPOINTMENT_DRAG_STARTED = 'appointment:drag-on',
  APPOINTMENT_DRAG_ENDED = 'appointment:drag-off',
  APPOINTMENT_DRAG_REVERTED = 'appointment:drag-reverted',
  UDV_LEARN_MORE_LINK_CLICKED = 'appointment:udv-learn-more-clicked',
  UDV_NEW_LEARN_MORE_LINK_CLICKED = 'appointment:udv-new-learn-more-clicked',

  TIME_BLOCK_DRAG_STARTED = 'time-block:drag-on',
  TIME_BLOCK_DRAG_ENDED = 'time-block:drag-off',
  TIME_BLOCK_DRAG_REVERTED = 'time-block:drag-reverted',

  GET_APPOINTMENT_AVAILABILITY_REQUEST = 'GET_APPOINTMENT_AVAILABILITY_REQUEST',
  GET_APPOINTMENT_AVAILABILITY_RECEIVE = 'GET_APPOINTMENT_AVAILABILITY_RECEIVE',
  GET_APPOINTMENT_AVAILABILITY_FAILURE = 'GET_APPOINTMENT_AVAILABILITY_FAILURE',

  APPOINTMENT_GROUP_SAVED = 'appointment-group:saved',
  APPOINTMENT_GROUP_CONFIRMED = 'appointment-group:confirmed',
  APPOINTMENT_GROUP_REJECTED = 'appointment-group:rejected',
  APPOINTMENT_GROUP_SET_NOSHOW = 'appointment-group:set-no-show',
  APPOINTMENT_GROUP_CANCELLED = 'appointment-group:cancelled',

  CONSUMER_LIST_SEARCHED = 'consumer-list:searched',
  CONSUMER_LIST_PAGINATED = 'consumer-list:paginated',
  CONSUMER_LIST_FILTER_APPLIED = 'consumer-list:applied',
  CONSUMER_LIST_FILTER_DROPPED = 'consumer-list:dropped',
  CONSUMER_COMMISSION_LINK_CLICKED = 'consumer-commission-link:clicked',

  COMMISSION_ABOUT_CLICKED = 'commission:about-clicked',

  CUSTOMER_FOUND = 'customer:search-hit',
  CUSTOMER_SAVED = 'customer:saved',
  CUSTOMER_ARCHIVED = 'customer:archived',
  CUSTOMER_NEW_FORM_OPENED = 'customer:form-opened',

  CUSTOMER_DIALOG_OPENED = 'customer-dialog:open',
  CUSTOMER_DIALOG_CLOSED = 'customer-dialog:close',

  CUSTOMERS_IMPORT_FAILED = 'customers-import:failed',
  CUSTOMERS_IMPORT_MAPPING_OPENED = 'customers-import:mapping-opened',
  CUSTOMERS_IMPORT_SUCCESS = 'customers-import:success',

  CUSTOMERS_BULK_DELETE = 'customers:bulk-delete',

  // eVoucher events
  EVOUCHER_REDEMPTION_OPEN = 'evoucher:redemption-dialog-opened',
  EVOUCHER_FOUND = 'evoucher:search-hit',
  EVOUCHER_REDEEMED = 'evoucher:redeemed',
  EVOUCHER_REDEEMED_WITH_APPOINTMENT = 'evoucher:redeemed-with-appointment',

  // Onboarding wizard
  ONB_WIZARD_SHOW = 'wizard:show',
  ONB_WIZARD_TOS_ACCEPTED = 'wizard:tos-accepted',
  ONB_WIZARD_OPENING_TIMES_SAVED = 'wizard:opening-times-saved',
  ONB_WIZARD_MENU_SAVED = 'wizard:quick-menu-saved',
  ONB_WIZARD_DONE = 'wizard:done',

  // Bookings
  BOOKING_OPENED = 'booking:dialog-opened',
  BOOKING_CLOSED = 'booking:dialog-closed',
  BOOKING_CONSUMER_PHONE_CLICKED = 'booking:phone-clicked',
  BOOKING_CONSUMER_EMAIL_CLICKED = 'booking:email-clicked',
  BOOKING_CONFIRMED = 'booking:confirmed',
  BOOKING_REJECTED = 'booking:rejected',
  BOOKING_ACCESS_FORBIDDEN = 'booking:forbidden',

  ORDER_CONFIRMED = 'order:confirmed',

  // Settings:
  VENUE_SAVED = 'venue:saved',
  SETTINGS_POLICIES_SAVED = 'settings:policies-saved',
  SETTINGS_BANK_DETAILS_SAVED = 'settings:bank-details-saved',
  SETTINGS_BILLING_DETAILS_SAVED = 'settings:billing-details-saved',
  OPENING_HOURS_SAVED = 'settings:opening-hours-saved',

  SETTINGS_FULFILLMENT_COMMUNICATION_SAVED = 'fulfillment-communication:saved',
  SETTINGS_BOOKING_WIDET_ENABLED = 'settings:booking-widget-enabled',
  SETTINGS_BOOKING_WIDET_DISABLED = 'settings:booking-widget-disabled',

  // Employees
  EMPLOYEE_ADDED = 'employee:added',
  EMPLOYEE_EDITED = 'employee:edited',
  EMPLOYEE_ARCHIVED = 'employee:archived',
  EMPLOYEE_UNARCHIVED = 'employee:unarchived',

  EMPLOYEE_IMAGE_UPDATED = 'employee-image:updated',

  EMPLOYEE_CATEGORY_CREATED = 'employee-category:created',
  EMPLOYEE_CATEGORY_UPDATED = 'employee-category:updated',
  EMPLOYEE_CATEGORY_DELETED = 'employee-category:deleted',

  EMPLOYEE_LIST_SORTED = 'employee-list:sorted',

  EMPLOYEE_EXT_CALENDAR_SUBSCRIBED = 'employee:ext-cal-subscribed',
  EMPLOYEE_EXT_CALENDAR_UNSUBSCRIBED = 'employee:ext-cal-unsubscribed',

  ROOM_ALLOCATION_SAVED = 'room-allocation:saved',
  SHIFT_MODAL_CLOSE = 'calendar:shift-modal:closed',

  // Header search
  HEADER_SEARCH_RESULTS_OPENED = 'header-search:opened',
  HEADER_SEARCH_RESULT_CHOSEN = 'header-search:result-chosen',

  // Offer Menu
  PRICING_TYPE_CHANGE = 'menu:pricing-type-change',
  CHANGE_USE_PROCESSING_TIME = 'menu:change-use-processing-time',
  TRACK_SERVICE_EDIT = 'menu:track-service-edit',
  OPEN_OFFER_MODAL = 'menu:open-offer-modal',

  // 'Custom' events
  LIVECHAT_TOGGLE = 'livechat:toggled',
  NOTIFICATION_BAR_CLICKED = 'notifications:clicked',
  FAQ_LINK_CLICKED = 'faq-link:clicked',
  HOME_TABLE_FILTERED = 'home:table-filter-executed',
  RATING_LINK_CLICKED = 'rating-link:clicked',
  SALESFORCE_BOT_OPEN = 'salesforce-bot:open',

  // Media query change
  MEDIA_QUERY_CHANGE = 'media-query:change',

  // POS
  PRODUCTS_LIST_CHANGED = 'pos:products-list-changed',
  PRODUCT_REMOVED = 'product-form:reset',
  TRANSACTION_CREATED = 'pos:transaction-created',
  TRANSACTION_CANCELLED = 'pos:transaction-cancelled',
  POS_DAY_CLOSED = 'pos:day-closed',

  // Iglu dialogs
  IGLU_DIALOG_CLOSE = 'iglu:dialog-close',

  // Covid venue closing
  COVID_VENUE_CLOSED = 'covid:venue-closed',

  TRACKING_CONTAINER_FIRE_EVENT = 'tracking-container:fire-event',
}
