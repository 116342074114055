import React, { useState, useCallback } from 'react';
import { SubmitSuccessPage } from 'components/marketing/ClientForms/FeatureSignup/SubmitSuccessPage';
import { SubmitFailurePage } from 'components/marketing/ClientForms/FeatureSignup/SubmitFailurePage';
import { ComingSoonPage } from 'components/marketing/ClientForms/FeatureSignup/ComingSoonPage';
import App from 'common/backbone-app';
import { useStorage } from 'utilities/hooks/useStorage';

const GOOGLE_FORMS_ID = App.isProd
  ? '1FAIpQLSf5nLY22pRuqEHordPowKx5Ie-aTQTCJlMo2mwAcJx2cVljuQ'
  : '1FAIpQLSfxPohSKfi18tbseiDAF6H4BPXbCfcaW0wbWBXYNdi9VZHjCA';

enum Singup {
  SUCCESS,
  FAILURE,
  NOT_SENT,
}

const CLIENT_FORMS_FEATURE_SIGNUP_LOCALSTORAGE_KEY = 'clientFormsFeatureSignupDone';

export const FeatureSignupPage = () => {
  const [signup, setSignup] = useState<Singup>(Singup.NOT_SENT);
  const [signupDone, setSignupDone] = useStorage<string>(
    CLIENT_FORMS_FEATURE_SIGNUP_LOCALSTORAGE_KEY,
  );

  const formatBody = () =>
    new URLSearchParams({
      'entry.1627207244': App.getVenueId(), // it is the same entry ID for prod
    });

  const handleSubmit = useCallback(() => {
    fetch(`https://docs.google.com/forms/d/e/${GOOGLE_FORMS_ID}/formResponse`, {
      method: 'POST',
      mode: 'no-cors',
      body: formatBody(),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then((response) => {
        if (response.status != 0 && response.status != 200) {
          setSignup(Singup.FAILURE);
        } else {
          setSignup(Singup.SUCCESS);
          setSignupDone('true');
        }
      })
      .catch(() => setSignup(Singup.FAILURE));
  }, []);

  if (signupDone) {
    return <SubmitSuccessPage />;
  }

  switch (signup) {
    case Singup.NOT_SENT:
      return <ComingSoonPage callback={handleSubmit} />;
    case Singup.SUCCESS:
      return <SubmitSuccessPage />;
    case Singup.FAILURE:
      return <SubmitFailurePage callback={handleSubmit} />;
  }
};
