import App from 'common/backbone-app';
import { Action, ActionWithPayload } from 'reduxStore/actionCreator';
import { getAppointmentAvailabilityRequestAction } from 'components/calendar/CalendarEventEditor/store/actions';
import {
  offerDialogSaved,
  resetServiceEditTrackingAction,
  trackServiceEditAction,
} from 'components/menu/actions';

import { resetCovidBannerState } from 'components/CovidBanner/actions';
import * as TrunkFeaturesActions from 'common/trunk-features/store/actions';
import { AppEvent } from '../../../javascripts/wahanda/AppEvents';
import { setAppSettings } from 'reduxStore/common/appSettings/actions';

export function backboneToReact(dispatch) {
  appEvents(
    dispatch,
    on(AppEvent.GET_APPOINTMENT_AVAILABILITY_REQUEST, (payload) =>
      getAppointmentAvailabilityRequestAction(payload),
    ),
    on(AppEvent.TRACK_SERVICE_EDIT, trackServiceEditAction),
    on(AppEvent.OFFER_DIALOG_SAVED, offerDialogSaved),
    on(AppEvent.RESET_SERVICE_EDIT_TRACKING, resetServiceEditTrackingAction),
    on(AppEvent.APP_VENUE_CHANGED, resetCovidBannerState),
    on(AppEvent.APP_FEATURES_REQUEST, TrunkFeaturesActions.requestFeatures),
    on(AppEvent.APP_CONFIG_LOADED, (payload) => setAppSettings(payload)),
  );
}

function on(
  event: AppEvent,
  actionFn: (payload: any) => Action<string> | ActionWithPayload<string, any>,
) {
  return {
    name: event,
    actionFn,
  };
}

function appEvents(dispatch, ...args: ReturnType<typeof on>[]) {
  args.forEach((arg) => {
    App.on(arg.name, (payload) => {
      if (!App.isProd) {
        console.log('Redux Backbone Event:', arg.name, payload);
      }
      dispatch(arg.actionFn(payload));
    });
  });
}
