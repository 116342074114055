import React from 'react';
import { Button } from 'components/common/Button';
import styles from './../OnboardingWidget.scss';
import { useGoLive } from 'components/shared/OnboardingWidget/hooks/useGoLive';
import { getTrackingByEventName } from 'src/tracking-container/TrackingEvents';

const trackEvent = getTrackingByEventName('self_onboarding_get_online');

export const GetCompleted = () => {
  const [goLive] = useGoLive();

  /**
   * To prevent venues from going live, we show a different message and button label
   * but we still want to perform the same action when the button is clicked.
   *
   * With this behaviour the venue will switch his status to pending
   * and the onboarding will be completed by removing the onboarding widget.
   *
   * The venue will not be live either way because it will require a manual approval.
   */
  const i18n = Wahanda.Features.isEnabled('CPG-63-self-onboarding-no-go-live')
    ? {
        description: Wahanda.lang.onboardingWidget.getCompleted.description,
        buttonLabel: Wahanda.lang.onboardingWidget.getCompleted.button.label,
      }
    : {
        description: Wahanda.lang.onboardingWidget.getOnline.description,
        buttonLabel: Wahanda.lang.onboardingWidget.getOnline.button.label,
      };

  const handleOnClick = () => {
    trackEvent();
    goLive();
  };

  return (
    <>
      <p className={styles.description}>{i18n.description}</p>
      <div className={styles.button}>
        <Button
          colour="default"
          label={i18n.buttonLabel}
          onClick={handleOnClick}
          type="button"
          variant="primary"
          fullWidth
        />
      </div>
    </>
  );
};
