import React from 'react';
import style from 'components/marketing/ClientForms/FeatureSignup/FeatureSignup.scss';
import Wahanda from 'common/wahanda';

const lang = Wahanda.lang.marketing.clientForms.landingPage.submitSuccess;

export const SubmitSuccessPage = () => (
  <div className={style.container} data-test="successful-consultation-forms-sign-up-box">
    <div className={style.icon + ' ' + style.submitSuccessIcon} />
    <p>
      <strong>{lang.header}</strong>
    </p>
    <p>{lang.text}</p>
  </div>
);
