/* eslint-disable func-names */
/* global _ Backbone BackboneEx */
import { intercomInit, seedGenericContext } from 'common/analytics';
import App from 'common/backbone-app';
import { ACCOUNT_LOCALE_LOCAL_STORAGE_KEY } from 'components/AccountLanguageSelect/constants';
import localesConfig from 'config/domains-locales';
import { L1Page, mapPageToL1Enum, NavigationAnalytics } from 'common/tracking/navigation';

import apiUrl from 'common/api-url';
import { xhr } from 'common/xhr';
import { openChat } from 'utilities/chat';
import { getTrackingByEventName } from 'src/tracking-container/TrackingEvents';

const trackQuickSearch = getTrackingByEventName('quick_search');

App.Views.Header = Backbone.View.extend({
  events: {
    'click #logout': 'onLogout',
    'click #nav1 a': 'onModuleChange',
    'click #nav-settings a': 'onSettingsClick',
    'click .js-livechat a': 'showLiveChat',
    'click .js-language a': 'setLanguage',
  },

  notificationsView: null,
  dropdownsSetup: false,

  initialize() {
    // Renders itself on each wizard finish, or every 5 mins.
    this.notificationsView = new App.Views.Header.Notifications({
      el: this.$('#nav-notifications'),
    });
    this.iconsView = new App.Views.Header.Icons({
      el: this.el,
    });
    if (!App.isTaxAuditor()) {
      this.searchView = new App.Views.Header.Search({
        el: this.$('#header-search'),
      });
    } else {
      $('body').addClass('tax-auditor');
    }
    App.on(Wahanda.Event.APP_LOADED, $.proxy(this.setCalendarVisibility, this));

    const hasAccess = !App.isTaxAuditor() && Wahanda.Permissions.canAccessMarketingTools();
    this.$('#nav-marketing').toggleClass('no-permission', !hasAccess);

    App.on(Wahanda.Event.APP_LOADED, () => {
      Wahanda.Tracking.identify();
      Wahanda.Tracking.group();
      Wahanda.Tracking.page();
      seedGenericContext();
      intercomInit();
      this.fetchFBEInstallStatus();
    });

    App.on(Wahanda.Event.COVID_VENUE_CLOSED, () => {
      this.saveCovidBannerAsClosed();
      this.hideCovidBanner();
    });
    App.on(Wahanda.Event.VENUE_CHANGED, () => {
      this.hideCovidBanner();
      this.renderCovidShutdownExit();

      this.fetchFBEInstallStatus();
    });

    this.initSearchDropdown();
  },

  initSearchDropdown() {
    const $searchTrigger = document.getElementById('dropdown-trigger');
    const $searchDropdown = document.getElementById('dropdown-search');
    const $searchInput = document.getElementById('dropdown-search')?.querySelector('input');

    const close = () => {
      $searchDropdown?.classList.add('hidden');
      $searchTrigger?.classList.remove('active');
    };

    const isOpen = () => !$searchDropdown?.classList.contains('hidden');

    const toggleSearchDropdown = () => {
      $searchDropdown?.classList.toggle('hidden');
      $searchTrigger?.classList.toggle('active');
      if (isOpen()) {
        $searchInput?.focus();
      }
    };

    const trackFocus = () => trackQuickSearch({ action: 'focus' });

    const clickOutsideHandler = (event) => {
      if (!$searchDropdown?.contains(event.target) && !$searchTrigger?.contains(event.target)) {
        close();
      }
    };

    $searchTrigger?.addEventListener('click', toggleSearchDropdown);
    $searchInput?.addEventListener('focus', trackFocus);
    document.addEventListener('click', clickOutsideHandler);
  },

  renderUserDropdown(account, config) {
    const $initials = $(
      Wahanda.InitialsGenerator.generateHTML(
        account.masquerade ? 'T W' : config.accountEmployeeName() || config.accountFullName(),
      ),
    );

    const $pic = this.$('#user .current .person-pic-small');

    if (App.config.getAccountEmployeeId() && account.employeeImage) {
      const imageUrl = Wahanda.ScreenDensity.getImageFromStructure(
        account.employeeImage.uris,
        28,
        28,
      );
      const $img = $(`<img src="${imageUrl}" width="28" height="28" class="hidden">`);
      // Show the image only if it loads correctly
      $pic.empty().append($initials.add($img));
      $img.on('load', function () {
        $img.show();
        $initials.hide();
      });
    } else {
      $pic.empty().append($initials);
    }
    this.$('#user .ddown .name').text(config.accountEmployeeName() || config.accountFullName());
    $pic.toggleClass('masquerade', account.masquerade);

    const venueLocale = App.config.attributes.venue.locale
      ? App.config.attributes.venue.locale
      : App.domainLocale;
    const accountLocale = Wahanda.LocalStorage.get(ACCOUNT_LOCALE_LOCAL_STORAGE_KEY);
    const locale = accountLocale || venueLocale;

    this.$('.js-language-label').text(localesConfig.LOCALE_TO_TRANSLATION_MAP[locale]);
    this.$('.js-feedback').toggle(App.isFeatureSupported('connect-feedback'));
  },

  render() {
    const config = this.model;

    if (!config) {
      return;
    }

    const account = config.get('account');
    const venue = config.get('venue');
    const venues = config.get('venues');

    if (!account || !venue || !venues) {
      return;
    }

    this.renderCovidShutdownExit();
    this.renderUserDropdown(account, config);

    const kycVerificationNeeded = App.isIdentityVerificationNeeded();
    const bankAccountSyncNeeded = App.isBankAccountSyncNeeded();
    const isSelfOnboarding = App.isSelfOnboarding();

    // Self onboarding already has a KYC step
    const showKycBanner = (kycVerificationNeeded || bankAccountSyncNeeded) && !isSelfOnboarding;
    if (showKycBanner) {
      this.renderIdentityVerificationBanner(kycVerificationNeeded, bankAccountSyncNeeded);
    } else {
      this.hideIdentityVerificationBanner();
    }

    // venues
    const $venueName = this.$('#venues .current .name');
    $venueName.html(venue.name);
    if (venues.length > 1) {
      this.$('#venues').addClass('ddown-menu');
      this.$('#venues ul').addClass('ddown');
      this.renderVenueDropdown();
    } else {
      $venueName.center({ horizontal: false });
      $venueName.ellipsis();
    }

    // leave at the bottom of function

    // clean up style in order to have center plugin working for current venue name when venue is switched
    this.$('.ddown-menu .current .name').attr('style', null);
    this.$('.ddown-menu .current .name').center({ horizontal: false });
    this.$('.ddown-menu .current .name').ellipsis();

    this.notificationsView.reset();

    // Access to specific tabs
    const accessTests = {
      calendar: !App.isTaxAuditor(),
      settings: Wahanda.Permissions.accessSettings(),
      menu: Wahanda.Permissions.viewMenu(),
      team: Wahanda.Permissions.accessTeam(),
      products: Wahanda.Permissions.viewMenu(),
      dashboard: !App.isTaxAuditor(),
      clients: Wahanda.Permissions.viewClientList(),
      reports:
        Wahanda.Permissions.viewReports() ||
        App.isTaxAuditor() ||
        Wahanda.Permissions.canViewTransactionReport(),
    };

    _.forEach(
      accessTests,
      function (isVisible, module) {
        this.$(`#nav-${module}`).wToggle(isVisible);
      },
      this,
    );

    this.iconsView.render();

    if (!this.dropdownsSetup) {
      this.dropdownsSetup = true;
      // The dropdown functionality for Venue and User dropdowns
      this.$('.ddown-menu').headerDropdown();
    }

    this.renderSearch();
  },

  setModule(moduleName) {
    this.$(`#nav-${moduleName}`).addClass('on');
  },

  renderVenueDropdown() {
    const $dropdown = this.$('#venues ul');
    $dropdown.empty();

    if (!this.model) {
      return;
    }

    const currentVenue = this.model.get('venue');
    const venues = this.model.get('venues');
    if (!venues || !currentVenue) {
      return;
    }

    const venuesInDropdown = venues.map((venue) => {
      let item;
      if (venue.id === currentVenue.id) {
        item = _.clone(venue);
        item.on = true;
      }
      return item || venue;
    });

    if (venuesInDropdown.length > 0 && $('#header-venues-dropdown-items').length !== 0) {
      $dropdown.append(
        Wahanda.Template.renderTemplate('header-venues-dropdown-items', {
          venues: venuesInDropdown,
        }),
      );
    }
  },

  /**
   * Toggles the calendar visiblility based on permissions.
   */
  setCalendarVisibility() {
    if (App.isTaxAuditor()) {
      // No calendar visibility for tax auditors. This is already handled.
      return;
    }

    const $calendar = this.$('#nav-calendar');

    // Has permissions or can perform services.
    $calendar.wShow();
  },

  renderSearch() {
    if (this.searchView) {
      this.searchView.render();
    }
  },

  /**
   * Handles clicks on module icons.
   *
   * This method triggers a "change-module" event, which other views can intercept and cancel.
   *
   * All event handlers are passed a callback function which, if called, will prevent action.
   */
  onModuleChange(event) {
    let allowChange = true;
    const stopChangeCallback = function () {
      allowChange = false;
    };

    this.trigger('change-module', stopChangeCallback);

    if (!allowChange) {
      event.preventDefault();
    } else {
      NavigationAnalytics.trackMainMenuClick(mapPageToL1Enum(event.currentTarget.dataset.page));
    }
  },

  onSettingsClick() {
    NavigationAnalytics.trackMainMenuClick(L1Page.SETTINGS);
  },

  onLogout(event) {
    event.preventDefault();
    BackboneEx.Tool.Analytics.trackEvent('connect', 'logout');
    App.logout();
  },

  showLiveChat(event) {
    event.preventDefault();
    openChat('header');
  },

  setLanguage(event) {
    event.preventDefault();
    App.ES6.Initializers.AccountLanguageSelect.render();
  },

  renderCovidShutdownExit() {
    const closedVenues = this.getClosedCovidBannerVenues();
    if (closedVenues.includes(App.getVenueId())) {
      this.hideCovidBanner();
      return;
    }
    if (
      !App.isFeatureSupported('market-shutdown') &&
      !App.isFeatureSupported('limited-operation')
    ) {
      this.hideCovidBanner();
      return;
    }

    if (App.isWebview()) {
      this.hideCovidBanner();
      return;
    }

    App.ES6.Initializers.CovidBanner({
      node: document.getElementById('covid-shutdown-exit'),
    }).render();
  },

  renderIdentityVerificationBanner(kycVerificationNeeded, bankAccountSyncNeeded) {
    const isKycNewSupplier = App.isKycNewSupplier();
    App.ES6.Initializers.KycBanner({
      kycVerificationNeeded,
      bankAccountSyncNeeded,
      isKycNewSupplier,
      node: document.getElementById('kyc-verification-container'),
    }).render({});
  },

  renderOldIdentityVerificationBanner() {
    App.ES6.Initializers.OldKycBanner({
      node: document.getElementById('kyc-verification-container'),
    }).render({});
  },

  hideIdentityVerificationBanner() {
    App.ES6.Initializers.KycBanner({
      node: document.getElementById('kyc-verification-container'),
    }).destroy();
  },

  hideCovidBanner() {
    App.ES6.Initializers.CovidBanner({
      node: document.getElementById('covid-shutdown-exit'),
    }).destroy();
  },

  saveCovidBannerAsClosed() {
    const closedVenues = this.getClosedCovidBannerVenues();
    closedVenues.push(App.getVenueId());
    Wahanda.LocalStorage.set('covid-venue-close-complete', JSON.stringify(closedVenues));
  },

  fetchFBEInstallStatus() {
    Wahanda.LocalStorage.removeForVenue('fbe-installed');

    if (!Wahanda.Features.isEnabled('CAR-1652-pass-fbe-status-to-optimizely')) {
      return;
    }

    if (!Wahanda.Permissions.canAccessMarketingTools()) {
      return;
    }

    xhr.getWithPromise(apiUrl('FBE_CHECK_STATUS')).done((response) => {
      Wahanda.LocalStorage.setForVenue('fbe-installed', response.installed);
    });
  },

  getClosedCovidBannerVenues() {
    return JSON.parse(Wahanda.LocalStorage.get('covid-venue-close-complete') || '[]');
  },
});
