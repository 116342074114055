import React from 'react';
import style from 'components/marketing/ClientForms/FeatureSignup/FeatureSignup.scss';
import Wahanda from 'common/wahanda';
import { Button } from 'components/common/Button';

const lang = Wahanda.lang.marketing.clientForms.landingPage.submitFailure;

interface SubmitFailurePageProps {
  callback: () => void;
}

export const SubmitFailurePage: React.FC<SubmitFailurePageProps> = ({ callback }) => (
  <div className={style.container} data-test="failed-consultation-forms-sign-up-box">
    <div className={style.icon + ' ' + style.submitFailureIcon} />
    <p>
      <strong>{lang.header}</strong>
    </p>
    <p>{lang.text}</p>
    <Button label={lang.buttonText} onClick={callback} />
  </div>
);
