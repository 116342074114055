import React, { useEffect, useState } from 'react';
import { LayoutContainer, LayoutGrid, LayoutHeader } from 'components/common/Layout';
import style from './ClientForms.scss';
import { Box } from 'components/common/Box';
import Wahanda from 'common/wahanda';
import { FAQs, FAQsProps } from 'components/shared/FAQs';
import { L2Page, NavigationAnalytics } from 'common/tracking/navigation';
import { FormTemplates } from './FormTemplates/FormTemplates';
import { Button } from 'components/common/Button';
import { ClientFormsCreate } from './ClientFormsCreate/ClientFormsCreate';
import { FeatureSignupPage } from 'components/marketing/ClientForms/FeatureSignup/FeatureSignupPage';
import { SHOW_CONSULTATION_FORMS_LIST_FEATURE_FLAG } from './FormTemplates/types';

const lang = Wahanda.lang.marketing.clientForms;

const learnMoreLinks: FAQsProps = {
  header: lang.faq.header,
  trackCategory: L2Page.CLIENT_FORMS,
  questions: [
    {
      id: 'client-forms',
      name: lang.faq.aboutClientForms,
      link: lang.faq.aboutClientFormsLink,
    },
  ],
};

const getClientFormsSuccessMessage = (formTitle: string) =>
  Wahanda.Template.render(lang.creationSuccessMessage, { formTitle });

export const ClientForms = () => {
  const [showCreateForm, setShowCreateForm] = useState(false);

  useEffect(() => {
    NavigationAnalytics.trackPageEventView(L2Page.CLIENT_FORMS);
  }, []);

  function handleCreateFormClose<T>(createdFormTitle?: T) {
    if (createdFormTitle)
      Wahanda.ToastNotifications.sendMessage(
        getClientFormsSuccessMessage(createdFormTitle as string),
      );
    setShowCreateForm(false);
  }

  const isConsultationFormsEnabled = Wahanda.Features.isEnabled(
    SHOW_CONSULTATION_FORMS_LIST_FEATURE_FLAG,
  );

  return (
    <LayoutContainer
      footer={
        isConsultationFormsEnabled && (
          <Button
            dataTest="client-forms-create-button"
            label={lang.addNewForm}
            colour="default"
            variant="primary"
            onClick={() => setShowCreateForm(true)}
            size="large"
          />
        )
      }
    >
      <LayoutGrid>
        {showCreateForm && <ClientFormsCreate onClose={handleCreateFormClose} />}
        <div className={style.settings}>
          <div className={style.boxWrapper}>
            <Box dataTest="client-forms-header-box">
              <div>
                <LayoutHeader header={lang.header} />
                <div>{lang.description}</div>
              </div>
            </Box>
          </div>
          <div className={style.boxWrapper}>
            {isConsultationFormsEnabled ? <FormTemplates /> : <FeatureSignupPage />}
          </div>
        </div>
        <FAQs {...learnMoreLinks} />
      </LayoutGrid>
    </LayoutContainer>
  );
};
