import React from 'react';
import ReactDOM from 'react-dom';
import { Initializer } from 'common/types/initializers';
import { Reconnect } from 'federation/Reconnect';
import { track } from 'src/tracking/trackingService';

export const OnboardingInitializer = (): Initializer => {
  const targetNode = document.createElement('div');
  document.body.appendChild(targetNode);
  return {
    destroy: () => ReactDOM.unmountComponentAtNode(targetNode),
    render: () => {
      ReactDOM.render(
        <Reconnect
          name="Onboarding"
          props={{
            notifyFn: Wahanda.ToastNotifications.sendMessage,
            trackingService: {
              trackEvent: ({ name, payload }) => track(name, payload),
            },
          }}
        />,
        targetNode,
      );
    },
  };
};
